import {
  Context,
  InputType,
  TransactionDirection,
  TransactionTemplateFragment,
  namedOperations,
  usePaymentOccasionQuery,
  useSendTransactionRequestMutation,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { PanelFooter } from "@earnnest-e2-frontend/platform-ui/src/mantine/Panel"
import {
  BackgroundImage,
  Box,
  Button,
  Center,
  Checkbox,
  Group,
  Input,
  Loader,
  Paper,
  RangeSlider,
  Select,
  Space,
  Stack,
  Text,
  TextInput,
  Textarea,
  Title,
  useMantineTheme,
} from "@mantine/core"
import { useForm, yupResolver } from "@mantine/form"
import { showNotification } from "@mantine/notifications"
import numeral from "numeral"
import { useMemo } from "react"
import MaskedInput from "react-text-mask"
import { createNumberMask } from "text-mask-addons"
import * as yup from "yup"

export default function CreateGenericRequestForm({
  paymentOccasionId,
  onSubmitSuccess,
}: {
  paymentOccasionId: string
  onSubmitSuccess: (transactionTemplate: TransactionTemplateFragment) => void
}) {
  const theme = useMantineTheme()

  const paymentOccasionQuery = usePaymentOccasionQuery({
    variables: {
      id: paymentOccasionId,
    },
    skip: !paymentOccasionId,
  })
  const paymentOccasion = paymentOccasionQuery.data?.paymentOccasion

  const [
    sendTransactionRequest,
    sendTransactionRequestResult,
  ] = useSendTransactionRequestMutation({
    refetchQueries: [namedOperations.Query.TransactionTemplates],
  })

  const schema = useMemo(() => {
    let max = paymentOccasion?.limit ? paymentOccasion.limit / 100 : 100000
    let shape = {
      amount: yup
        .string()
        .required("Required")
        .typeError("Required")
        .test("Min", `Min $36`, (x) => numeral(x).value() >= 36)
        .test(
          "Max",
          `Max ${numeral(max).format("$0,0[.]00")}`,
          (x) => numeral(x).value() <= max,
        ),
      receiverEmail: yup.string().email("Invalid email").required("Required"),
    }
    paymentOccasion?.formFields.forEach((field) => {
      let validation = yup.string()
      if (field.required) {
        validation = validation.required(`${field.label} is required`)
      }
      if (field.type === InputType.Email) {
        validation = validation.email("Enter a valid email")
      }
      if (field.type === InputType.Url) {
        validation = validation.url("Enter a valid url")
      }
      shape[field.name] = validation
    })
    return yup.object(shape)
  }, [paymentOccasion])

  const form = useForm({
    initialValues: {
      amount: "",
      receiverEmail: "",
      ...paymentOccasion?.formFields?.reduce((fields, field) => {
        return { ...fields, [field.name]: "" }
      }, {}),
    },
    validate: yupResolver(schema),
  })

  if (!paymentOccasionId) {
    return null
  }

  if (paymentOccasionQuery.loading) {
    return (
      <Center h="70vh">
        <Loader />
      </Center>
    )
  }

  if (
    paymentOccasionQuery.error ||
    !paymentOccasion ||
    paymentOccasion.context !== Context.Generic ||
    paymentOccasion.direction !== TransactionDirection.Inbound
  ) {
    return (
      <Text color="red">
        {paymentOccasionQuery.error?.message ||
          "Unable to load a valid payment occasion."}
      </Text>
    )
  }

  return (
    <form
      autoComplete="off"
      onSubmit={form.onSubmit(async (values) => {
        try {
          const result = await sendTransactionRequest({
            variables: {
              request: {
                amount: parseInt(
                  (numeral(values.amount).value() * 100).toFixed(0),
                ),
                receiverEmail: values.receiverEmail,
                formFields: paymentOccasion?.formFields?.map((field) => ({
                  name: field.name,
                  value: values[field.name],
                })),
                lineNumber: 1,
              },
              paymentOccasionId: paymentOccasion.id,
            },
          })
          onSubmitSuccess(result.data.sendTransactionRequest)
          showNotification({
            title: "Success",
            message: "Payment request sent",
            color: "green",
          })
        } catch (error) {
          showNotification({
            title: "Error",
            message: error.message,
            color: "red",
          })
        }
      })}>
      <Title size="h2" order={2}>
        Request Payment
      </Title>
      <Text>
        Fill out the relevant details below to request payment on behalf of{" "}
        {paymentOccasion.organization.name}.
      </Text>
      <Stack>
        <Space h="sm" />
        <Title order={5}>Payment Information</Title>
        <Paper
          bg="white"
          withBorder
          radius="md"
          sx={{
            overflow: "hidden",
          }}>
          <Group position="center">
            <Box
              p="sm"
              bg={
                paymentOccasion.organization?.backgroundColor ||
                theme.fn.themeColor("dark")
              }>
              <BackgroundImage
                src={paymentOccasion.organization?.logoLight || ""}
                sx={{
                  width: 80,
                  height: 60,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                }}
              />
            </Box>
            <Stack spacing={0} sx={{ flex: 1 }}>
              <Title order={5} mb={2}>
                {paymentOccasion.name}
              </Title>
              <Text size="sm" italic>
                {paymentOccasion.organization.name}
              </Text>
              <Text size="sm">{paymentOccasion.fundingSource.displayName}</Text>
            </Stack>
          </Group>
        </Paper>
        <Space h="xs" />
        <Title order={5}>Request Details</Title>
        <Group grow align="start">
          <Input.Wrapper
            label="Amount"
            {...form.getInputProps("amount")}
            sx={{ width: 200 }}>
            <Input
              placeholder="$0.00"
              autoComplete="off"
              component={MaskedInput}
              mask={createNumberMask({ allowDecimal: true })}
              guide={false}
              {...form.getInputProps("amount")}
            />
          </Input.Wrapper>
          <TextInput
            label="Payer Email"
            type="email"
            autoComplete="off"
            {...form.getInputProps("receiverEmail")}
          />
        </Group>
        {paymentOccasion.formFields.map((field) => {
          if (field.type === InputType.Textarea) {
            return (
              <Textarea
                key={field.name}
                label={field.label}
                {...form.getInputProps(field.name)}
              />
            )
          }
          if (field.type === InputType.Checkbox) {
            return (
              <Checkbox.Group
                key={field.name}
                label={field.label}
                {...form.getInputProps(field.name)}
                value={form.values[field.name]?.split(",")}
                onChange={(values) => {
                  form.setFieldValue(field.name, values.join(","))
                }}>
                <Stack spacing="xs" p={8}>
                  {field.options.map((option) => (
                    <Checkbox key={option} value={option} label={option} />
                  ))}
                </Stack>
              </Checkbox.Group>
            )
          }
          if (field.type === InputType.Select) {
            return (
              <Select
                key={field.name}
                label={field.label}
                data={field.options}
                {...form.getInputProps(field.name)}
              />
            )
          }
          if (field.type === InputType.Range) {
            return (
              <RangeSlider
                key={field.name}
                label={field.label}
                {...form.getInputProps(field.name)}
              />
            )
          }
          return (
            <TextInput
              type={
                field.type === InputType.Date
                  ? "date"
                  : field.type === InputType.Email
                  ? "email"
                  : field.type === InputType.Number
                  ? "number"
                  : field.type === InputType.Tel
                  ? "tel"
                  : field.type === InputType.Url
                  ? "url"
                  : "text"
              }
              key={field.name}
              label={field.label}
              autoComplete="off"
              {...form.getInputProps(field.name)}
            />
          )
        })}
      </Stack>
      <PanelFooter>
        <Button
          size="lg"
          type="submit"
          color="green"
          loading={sendTransactionRequestResult.loading}>
          Send Request
        </Button>
      </PanelFooter>
    </form>
  )
}
